@import '@ping/assets/scss/theme/variables';

.address-combo-box {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &__max-button {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: none;

    &[data-visible='true'] {
      transition: opacity 0.2s linear;
      visibility: visible;
      opacity: 1;
      pointer-events: visible;
    }
  }

  &__select {
    inline-size: fit-content;
    flex-grow: unset;
  }

  &__error-state {
    /* Caption 01 / Regular */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: $color-error;
    transition: all 0.3s 0.2s;
    z-index: 0;
    display: block;
    align-self: flex-end;
    margin: 0;

    &:empty {
      z-index: -10;
      transform: translateY(-300%);
      min-block-size: 1rem;
      display: none;
    }
  }

  &__empty-state {
    align-self: flex-end;
    margin: 0;

    color: $color-white;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
