@import '@ping/assets/scss/breakpoint';

.form-b-wizard-action {
  margin-block-start: 2rem;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up('lg') {
    margin-block-start: 2.5rem;
    gap: 1.5rem;
  }
}
