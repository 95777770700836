.text-substitute {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.coin-icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
