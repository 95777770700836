@import '@ping/assets/scss/theme/variables';

.form-submit {
  color: $color-white;

  /* 02 Body HC/semibold */
  font-family: inherit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.0175rem;
  text-transform: uppercase;
}
