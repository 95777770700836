@import '@ping/assets/scss/theme/variables';

.toggle-button-root {
  padding: 4px;
  background: $color-black;
  border-radius: 12px;
  border: 1px solid $color-gray-400;
  isolation: isolate;

  &__list {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__selection {
    inline-size: var(--button-group-selection-inline-size); // will be set in React component
    transform: translateX(var(--button-group-selection-offset-x)); // will be set in React component
    will-change: transform, width;
    transition: transform 0.3s, width 0.15s;

    border-radius: 8px;
    border: 1px solid $color-gray-400;
    position: absolute;
    inset-block-start: 0;
    z-index: -1;
    block-size: 100%;
    background-color: $color-gray-500;
  }
}
