@import '@ping/assets/scss/breakpoint';

.crypto-transaction {
  position: relative;
  height: 100%;

  &__toggle {
    margin-block-end: 1.5rem;
    @include media-breakpoint-up('lg') {
      margin-block-end: 2rem;
    }
  }

  &__content {
    // 92px => 60px height of the tab component, 32px margin-bottom of that.
    height: calc(100% - 92px);
    &[aria-hidden='true'] {
      display: none;
      pointer-events: none;
    }
  }
}
