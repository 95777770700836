@import '@ping/assets/scss/theme/variables';

.field-group {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input {
    width: 100%;
  }
}

.field-group-label {
  color: $color-gray-100;
  margin: 0;
  user-select: none;
  max-inline-size: fit-content;
}
