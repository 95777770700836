@import '@ping/assets/scss/theme/variables';

.listbox {
  list-style: none;
  overflow-y: auto;
  outline: none;
  background-color: $color-gray-600;

  &__group {
    color: $color-gray-100;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    &:not(:first-child) {
      display: block;
      margin-top: 24px;
    }

    &:empty {
      display: none;
    }
  }
}

.listbox-option {
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:focus-visible {
    outline: none;
    background-color: $color-primary-light;
  }

  &:hover {
    background-color: $color-gray-500;
  }

  &[aria-selected='true']:not(:focus-visible) {
    background-color: $color-gray-500;
  }
}
