@import '@ping/assets/scss/theme/variables';

.toggle-button-choice {
  color: $color-white;

  /* 02 Body/medium */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.00875rem;

  margin: 0;
  padding: calc(0.75rem - 1px) 1.5rem;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  flex: none;
  order: 1;
  flex-grow: 1;

  &[aria-current='false'] {
    cursor: pointer;
  }
  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &[data-focus-visible='true'] {
    transition: all 0.2s 0.2s; // delay .2s to be sure the selection animation in ToggleButton is completed
    background-color: $color-primary-light;
  }
  &[data-compact='true'] {
    padding: calc(0.5rem - 1px) 1.5rem;
  }

  &:has(input:focus:not(:focus-visible)) {
    background-color: transparent;
  }
}
