@use 'sass:math';

@import '@ping/assets/scss/theme/variables';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tooltip-content {
  $indicator-inline-size: 0.5rem;
  $indicator-block-size: 1rem;
  $indicator-leg: math.hypot($indicator-inline-size, $indicator-block-size * 0.5);
  $space: 0.875rem;
  $border-width: 1px;

  padding: 0.75rem;
  min-inline-size: min-content;
  background-color: var(--tooltip-background-color, $color-gray-600);
  border: $border-width solid var(--tooltip-border-color, $color-gray-500);
  border-radius: 0.5rem;
  z-index: 999999 !important;
  user-select: none;
  animation: fade-in 0.2s ease-in-out;
  display: block;

  &[aria-hidden='true'] {
    display: none;
  }

  &:focus-visible {
    outline: none;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    rotate: 45deg;
    inline-size: $indicator-leg;
    block-size: $indicator-leg;
    background-color: var(--tooltip-background-color, $color-gray-600);
    border: $border-width solid var(--tooltip-border-color, $color-gray-500);
  }

  &[data-placement='top']::before {
    translate: -50% calc(-50% + $border-width);
    inset-block-start: 100%;
    inset-inline-start: 50%;
    border-block-start: none;
    border-inline-start: none;
  }

  &[data-placement='bottom']::before {
    translate: -50% calc(-50% - $border-width);
    inset-block-start: 0;
    inset-inline-start: 50%;
    border-block-end: none;
    border-inline-end: none;
  }

  &[data-placement='right']::before {
    translate: calc(-50% - $border-width) -50%;
    inset-block-start: 50%;
    inset-inline-start: 0;
    border-block-start: none;
    border-inline-end: none;
  }

  &[data-placement='left']::before {
    translate: calc(-50% + $border-width) -50%;
    inset-block-start: 50%;
    inset-inline-start: 100%;
    border-block-end: none;
    border-inline-start: none;
  }
}
