@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.progressBar {
  &__title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__text {
    color: $color-white;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  &_disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &__progress-bar {
    height: 4px;
    background: $color-gray-300;
    border-radius: 2px;

    &-red {
      .progress-bar {
        background-color: $color-error !important;
      }
    }

    .progress-bar {
      background: $color-primary;
      border-radius: 2px;
    }
  }
  &__limits-info {
    display: flex;
    column-gap: 8px;
  }

  &__icon {
    svg {
      color: $color-gray-200;
      inline-size: 1rem;
      block-size: 1rem;
      aspect-ratio: 1;
    }
  }
}
