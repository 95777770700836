@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.field-box {
  margin-block-start: auto;
  padding: 0.75rem 1rem;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  background: $color-gray-500;
  border: 1px solid $color-gray-500;
  block-size: 3rem;
  flex: none;
  flex-grow: 0;

  &:focus-within {
    transition: all 0.2s ease-out;
    border-color: transparent;
  }

  &[aria-invalid='true'] {
    transition: all 0.2s ease-out;
    border-color: $color-error !important;
  }

  &[data-magnet='end'] {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-inline-end-width: 0;
    padding-inline-start: 1rem;
    padding-inline-end: 0;
  }

  &[data-magnet='start'] {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    border-inline-start-width: 0;
    padding-inline-start: 0;
    padding-inline-end: 1rem;
  }

  &[data-magnet='both'] {
    border-radius: 0;
    border-inline-width: 0;
    padding-inline-end: 1rem;
    padding-inline-start: 0.5rem;

    & + & {
      padding-inline-start: 0;
    }
  }

  &:has(:is(input, button)[data-visible='false']) {
    padding: 0;
    margin: 0;
    inline-size: 0;
  }

  & > input {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    outline: none;
    flex-grow: 1;
    color: $color-white;

    /* 01 Body/regular */
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

@include media-breakpoint-between('lg', 'xl') {
  .field-box {
    &[data-magnet='both'] {
      padding-inline: 0.5rem;
    }
  }
}
