@import '@ping/assets/scss/theme/variables';

.asset-combo-box {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__max-button {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: none;
    max-width: 0;

    &[data-visible='true'] {
      transition: opacity 0.2s linear;
      visibility: visible;
      opacity: 1;
      pointer-events: visible;
    }
  }

  &__label {
    color: $color-gray-100;
    margin: 0;
    margin-block-end: 0.5rem;
    user-select: none;
    max-inline-size: fit-content;

    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__search {
    border-radius: 0.5rem;
    background-color: $color-gray-500;

    color: $color-gray-100;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* 01 Body/regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
  }

  &__balance {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: $color-gray-200;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    text-align: end;
    margin-block: 0;
    margin-inline-start: auto;
    transition: all 0.3s 0.2s;
    z-index: 0;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    &:empty {
      z-index: -10;
      transform: translateY(-300%);
      min-block-size: 1.125rem;
    }
  }

  &__error-state {
    margin: 0;
    align-self: flex-end;

    color: $color-error;
    font-size: 14px;
    line-height: initial;
    display: block;
  }

  &__empty-state {
    align-self: flex-end;
    margin: 0;

    color: $color-white;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
