@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.question-subtitle {
  margin: 0; // rest default style
  margin-block-start: 0.25rem;
  color: $color-gray-100;

  /* 01 Body/regular */
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
