.tag {
  display: inline-flex;
  align-items: center;
  padding-inline: 0.75rem;
  block-size: 2rem;
  gap: 0.25rem;

  border: 1px solid currentColor;
  border-radius: 1rem;
}
