@import '@ping/assets/scss/theme/variables';

.kyc-contact-section {
  padding: 32px;
  border-radius: 16px;
  border: 1px solid $color-gray-400;
  background: $color-gray-600;

  font-weight: 600;
  &__description {
    font-weight: 400;
    color: $color-gray-100;
  }
  &__email {
    margin-top: 24px;
    font-weight: 600;
  }
}
