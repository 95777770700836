@import '@ping/assets/scss/theme/variables';

.address-select {
  &__not-found {
    padding-block-start: 2rem;
    text-align: center;
    color: $color-gray-100;
  }

  &__search {
    border-radius: 0.5rem;
    background-color: $color-gray-500;

    color: $color-gray-100;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* 01 Body/regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__coin-icon {
    block-size: 1rem;
    inline-size: 1rem;
    border-radius: 100%;
  }

  &__name {
    color: $color-white;

    /* 02 Body/regular */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
