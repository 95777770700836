@import '@ping/assets/scss/theme/variables';

.select {
  $roundness: 0.5rem;

  &__control {
    flex-direction: column;
    align-items: stretch;

    border-radius: var(--trigger-block-start-border-radius, $roundness)
      var(--trigger-block-start-border-radius, $roundness) var(--trigger-block-end-border-radius, 0.5rem)
      var(--trigger-block-end-border-radius, 0.5rem);

    &[data-state='open'][data-placement='bottom'] {
      --trigger-block-end-border-radius: 0;
    }

    &[data-state='open'][data-placement='top'] {
      --trigger-block-start-border-radius: 0;
    }

    &[data-state='open'],
    &:hover {
      border-color: $color-gray-300;
      transition: all 0.24s ease;
    }
  }

  &__trigger {
    inline-size: 100%;
    block-size: 1.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;

    /* fix alignment issue when there is border involve */
    margin: -1px;
  }

  &__loading {
    inline-size: 1.5rem !important;
    block-size: 1.5rem !important;

    &[data-hidden='true'] {
      display: none;
    }
  }

  &__indicator {
    transition: transform 0.2s;

    &[data-hidden='true'] {
      display: none;
    }

    &[data-open='true'] {
      transform: rotate(180deg);
    }
  }

  &__tooltip {
    inline-size: 100% !important;
  }

  &__panel {
    padding: 1rem;
    max-block-size: 24.25rem !important; // to override the default max-block-size/max-height
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;

    background-color: $color-gray-600;

    &[data-placement='bottom'] {
      border-radius: 0 0 $roundness $roundness;
    }

    &[data-placement='top'] {
      border-radius: $roundness $roundness 0 0;
    }

    /* Chrome, Edge, and Safari */
    ::-webkit-scrollbar-track {
      /* color of the tracking area */
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      border-color: solid $color-gray-300 !important;
    }

    /* Firefox */
    * {
      scrollbar-color: $color-gray-300 transparent !important;
    }
  }

  &__empty-state {
    margin: 0;
    align-self: flex-end;
    margin-top: 0.5rem;

    color: $color-white;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
