@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.send-transaction {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  &[data-is-assetId-selected='false'] > section:is(#networks-dropdown) {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @include media-breakpoint-up('lg') {
    gap: 1.5rem;
  }

  &__divider {
    margin-inline-start: -2rem;
    inline-size: calc(100% + 2 * 2rem) !important;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__asset-fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-gray-100;

    /* 02 Body/regular */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  &__asset-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;

    /* 01 Body/regular */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__amount {
    /* Headlines/H4 */
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.01em;
  }

  &__address {
    margin-block-end: 0.5rem;
    position: relative;

    &-tooltip {
      width: auto;
      background-color: $color-gray-600;
      color: #fff;
      text-align: center;
      padding: 0.5rem;
      position: absolute;
      z-index: 1;
      bottom: 60px;
      left: 0;
      border-radius: 0.5rem;
      transition: opacity 0.3s;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $color-gray-600 transparent transparent transparent;
      }
    }

    @include media-breakpoint-up('lg') {
      margin-block-end: 0.75rem;
    }
  }

  &__asset-combo-box {
    margin-top: 1rem;
  }

  &__info {
    &_label {
      display: flex;
      justify-content: space-between;

      &-amount {
        color: $color-white;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    &-fee-text {
      display: flex;
      color: $color-gray-100;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__actions {
    padding-block-start: 0.5rem;
    display: flex;
    align-items: flex-end;
    gap: 1.5rem;
    flex: 1;

    @include media-breakpoint-up('lg') {
      padding-block-start: 0.75rem;
    }
  }

  &__button {
    /* 02 Body HC/semibold */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  &__save-popup__back-arrow {
    cursor: pointer;
    margin-right: 20px;
    display: block;
  }

  &__withdrawal-confirmation {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;

    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $color-gray-500;
      border-radius: 16px;
      padding: 56px;
      width: 456px;
    }

    &__qr-wrapper {
      background: $color-white;
      border-radius: 16px;
      padding: 20px;
      margin-bottom: 40px;
      text-align: center;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        display: block;
        text-align: center;
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
  }

  #networks-dropdown {
    margin-bottom: 1rem;
    &[data-disabled='true'] {
      opacity: 0.3;
    }
  }
}
