@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.kyc-custom {
  max-width: max-content;
}

.kyc-banned {
  display: flex;
  gap: 64px;

  @include media-breakpoint-down('lg') {
    flex-direction: column;
    gap: 32px;
  }

  &__left-side {
    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    &__description {
      font-weight: 400;
      color: $color-gray-100;
    }
  }

  &__right-side {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid $color-gray-400;
    background: $color-gray-600;

    font-weight: 600;
    &__description {
      font-weight: 400;
      color: $color-gray-100;
    }
    &__email {
      margin-top: 24px;
      font-weight: 600;
    }
  }
}
