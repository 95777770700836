@import '@ping/assets/scss/theme/variables';

.asset-select {
  &__selection > img {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }

  &__search {
    border-radius: 0.5rem;
    background-color: $color-gray-500;

    color: $color-gray-100;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* 01 Body/regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
  }

  &__not-found {
    padding-block-start: 2rem;
    text-align: center;
    color: $color-gray-100;
  }
}
