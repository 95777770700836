@import '@ping/assets/scss/theme/variables';

.radio-wrapper {
  display: inline-flex;
  width: 100%;
  &.vertical {
    flex-direction: column;
    .radio-label {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  &.horizontal {
    flex-direction: row;
    .radio-label {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  .radio-label {
    margin: 0;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    }
    input {
      display: none;
      &:checked ~ .radio-mark {
        border-color: $color-primary;
        &::after {
          transform: translate(50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }
    .radio-mark {
      display: inline-flex;
      width: 24px;
      height: 24px;
      background: transparent;
      border: 2px solid $color-gray-400;
      border-radius: 10rem;
      position: relative;
      transition: all 200ms ease;
      flex-shrink: 0;
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) scale(0);
        border-radius: 10rem;
        background: $color-primary;
        opacity: 0;
        transition: all 200ms ease;
      }
    }

    .radio-mark_small {
      width: 20px;
      height: 20px;
    }

    .radio-text {
      margin-left: 16px;
      color: $color-white;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
