@import '@ping/assets/scss/theme/variables';

.mobile-confirmation {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  block-size: 100vh;
  padding-inline: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 1.25rem 1rem;
    background: transparent !important;
  }

  &__logo {
    block-size: 2rem;
    max-inline-size: 100%;
  }

  &__close-button {
    all: unset;
    color: $color-white;
  }

  &__close-icon {
    color: $color-gray-100;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }

  &__divider {
    border-top: 0.5px solid $color-gray-400;
    margin: 0 -1rem;
  }

  &__title {
    margin-block-start: 3.5rem;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -1.88px;
    color: $color-white;
    text-align: start;
  }

  &__description {
    margin-block-start: 1rem;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -2%;
    color: $color-gray-100;
    text-align: start;

    a {
      color: $color-primary;
    }
  }

  &__cta {
    margin-block-start: 2rem;
  }

  &__footer {
    flex-grow: 1;
    margin-block-start: 2rem;
    padding-block-end: 2rem;
    display: flex;
    justify-content: center;
    place-items: flex-end;
    gap: 0.75rem;

    p {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -2%;
      color: $color-white;
      margin: 0;

      strong {
        font-weight: 700;
      }
    }
  }

  &__vertical-divider {
    border-right: 1px solid rgba($color-white, 0.2);
    margin: 0;
    block-size: 16px;
  }

  &__gdrp {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__check-icon {
    $size: 1rem;

    block-size: $size;
    inline-size: $size;
  }
}
