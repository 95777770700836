@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.combo-field {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  transition: opacity 0.3s;

  &:focus-within {
    [class*='field-box'] {
      transition: opacity 0.2s ease-out;
      border-color: $color-gray-300;
    }
  }

  &[data-disabled='true'] {
    opacity: 0.3;
  }

  &[data-popover-panel-open='true'][data-popover-panel-placement*='top'] {
    [class*='field-box'] {
      transition: opacity 0.2s ease-out;
      border-color: $color-gray-300;
    }

    [class*='field-box']:first-of-type {
      border-start-start-radius: 0;
    }

    [class*='field-box']:last-of-type {
      border-start-end-radius: 0;
    }
  }

  &[data-popover-panel-open='true'][data-popover-panel-placement*='bottom'] {
    [class*='field-box'] {
      transition: opacity 0.2s ease-out;
      border-color: $color-gray-300;
    }

    [class*='field-box']:first-of-type {
      border-end-start-radius: 0;
    }

    [class*='field-box']:last-of-type {
      border-end-end-radius: 0;
    }
  }
}

.combo-field-button {
  block-size: 1.5rem;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  flex: none;
  flex-grow: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 3px;
  border: 1px solid $color-gray-400;
  background-color: $color-gray-400;

  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 16px;
  color: $color-white;

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    inline-size: 0;
    padding: 0;
    margin: 0;
  }

  &[data-visible='true']:disabled {
    cursor: not-allowed;
  }

  &[data-visible='true']:not(:disabled):hover {
    border-color: $color-gray-300;
  }

  &[data-visible='true']:not(:disabled):focus-visible {
    outline: none;
    border-color: $color-gray-300;
  }

  &[data-visible='true']:not(:disabled):active {
    transition: transform 0.24s;
    transform: scale(0.95);
  }
}

.combo-field-divider {
  block-size: 1rem;
  inline-size: 1px;
  background: $color-gray-300;
}
