@import '@ping/assets/scss/theme/variables';

.contactCrypto {
  &__block {
    margin-bottom: 32px;
  }

  &__text {
    display: flex;
    margin-bottom: 8px;
    color: $color-gray-100;
  }

  &__description {
    margin-top: 16px;
    color: $color-gray-100;
  }

  &__arrow {
    display: block;
    margin-bottom: 32px;
    cursor: pointer;
  }

  &__input {
    padding: 12px 16px;
    height: 48px;
    width: 100%;
    background: $color-gray-500;
    border-radius: 3px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $color-white;

    &::placeholder {
      color: $color-gray-200;
    }

    &:focus {
      outline: none;
    }
  }

  &__error {
    color: $color-error;
    font-size: 14px;
    line-height: initial;
    display: block;
    margin-top: 8px;
  }
}
