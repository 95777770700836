@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.question-title {
  color: $color-white;
  align-self: stretch;
  text-wrap: balance;

  /* 01 Body/semibold */
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
