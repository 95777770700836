@import '@ping/assets/scss/theme/variables';

.selectable-option__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: $color-white;
  border: 1px solid $color-gray-400;
  border-radius: 0.5rem;
  padding: 0.875rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0;
  cursor: pointer;
  outline-color: $color-primary;

  transition: all 0.2s ease-in-out;

  &_checked {
    border-color: $color-primary;
  }

  .selectable-option__radio-wrapper {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }

  &:focus:not(&_checked) {
    outline-style: solid !important;
    background-color: $color-gray-600;
  }
}
