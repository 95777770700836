@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.enhance-assets-notice {
  background-color: $color-gray-700;
  border-radius: 8px;
  border: 1px solid $color-gray-500;
  padding: 16px;
  margin-block-start: 48px;

  &__header {
    color: $color-warning;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* 01 Body/semibold */
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    &-cancel-icon {
      cursor: pointer;
      path {
        stroke: $color-gray-100;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      color: $color-warning;
      margin-inline-end: 8px;
    }
  }

  &__description {
    color: $color-gray-100;
    margin-inline-start: 28px;
    /* 02 Body/regular */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-block-start: 24px;
  }
  &__footer-button {
    color: $color-primary-light;
    transition: $transition-hover;
    margin-inline-start: 32px;
    /* 01 Caption/semibold */
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    padding: 0;

    &:hover {
      color: $color-white;
    }
  }
}
