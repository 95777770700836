@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.page-spinner {
  position: fixed;
  inset: calc($header-height * 0.5) 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color-black, 0.7);
  color: $color-white;
  z-index: 10;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    background: $color-gray-500;
    width: 72px;
    height: 72px;
    z-index: -1;
    border-radius: 16px;
  }
}
