@import '@ping/assets/scss/theme/variables';

.field-group {
  input {
    padding-inline: 8px;
    &::placeholder {
      color: $color-gray-200;
    }
  }
}
