@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.compliance {
  margin-inline: auto;
  max-inline-size: 70.25rem;
  display: grid;
  justify-content: center;
}

.portfolio {
  .wallet {
    display: block;
    border-left: 0.5px solid $color-gray-400;
  }

  &__head {
    border-bottom: 0.5px solid $color-gray-400;
    padding-block-end: 40px;

    @media (min-width: 768px) and (max-width: 990px) {
      padding-left: 0;
    }

    @media (max-width: 767px) {
      padding-left: 1px;
      padding-right: 1px;
    }
  }

  &__amount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up('md') {
      flex-direction: row;
    }

    @include media-breakpoint-up('lg') {
      padding-inline-end: 2rem;
    }
  }

  &__asset-info {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('md') {
      margin-inline: 2rem;
    }

    &_amount {
      display: block;
      margin-top: 12px;
      overflow-wrap: anywhere;
    }
  }

  &__asset-info:first-child,
  &__asset-info:last-child {
    margin-inline: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    margin-block-start: 42px;
    margin-bottom: 15px;
    align-items: center;

    & > * {
      width: 100% !important;
      max-width: 316px !important;
    }

    @include media-breakpoint-up('lg') {
      margin-block-start: 0;
      margin-bottom: 0;
      align-items: self-end;
    }
  }

  &__ramp {
    padding: 0.875rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 0.1875rem;
    border: 1px solid $color-primary;
    color: $color-white;
    transition: all 0.24s ease-in;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
    letter-spacing: 0.0175rem;
    text-transform: uppercase;

    &:hover:not(.disabled) {
      color: $color-white;
      text-decoration: none;
      border-color: $color-primary-medium;
    }

    &:disabled {
      background-color: $color-gray-600;
      border-color: $color-gray-300;
      color: $color-gray-300;
      cursor: not-allowed;
    }
  }

  &__assets {
    padding-block: 25px 40px;

    [class*='empty-view__wrapper'] {
      position: relative;
      top: 0;
      transform: translateX(-50%);
      margin-block-start: 40px;
    }

    @media (max-width: 767px) {
      padding-left: 1px;
      padding-right: 1px;
      padding-top: 32.5px;
    }
  }

  &__asset-table {
    margin-top: 36px;
    width: 100%;

    tbody {
      tr {
        td {
          img {
            height: 1rem;
            width: 1rem;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@keyframes modal-slide {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@include media-breakpoint-up('md') {
  .crypto-transaction--modal {
    left: unset;
    right: 0;
    top: 0;
    bottom: 0;
    transform: unset;

    &-content {
      height: 100%;
      max-height: 100%;
      animation: modal-slide 0.2s linear;
    }
  }
}
