@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

@keyframes pulse {
  to {
    opacity: 0;
  }
}

.sign-form-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  gap: 1.5rem;

  @include media-breakpoint-up('md') {
    max-inline-size: 41.875rem;
    justify-content: center;
    gap: 2rem;
  }

  &__wrap {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  &__title {
    margin: 0; // reset browser style
    color: $color-white;
    text-wrap: balance;

    /* Headlines/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 150% */
    letter-spacing: -0.0125rem;
  }

  &__description {
    margin: 0; // reset browser style
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  &__emphasis {
    color: $color-white;

    /* 01 Body/semibold */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    &:focus-visible {
      background-color: $color-primary-light;
      color: $color-white;
      outline: none;
    }
  }

  &__information {
    margin: 0; // reset browser style
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;
  }

  &__information-group {
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @include media-breakpoint-up('md') {
      grid-template-columns: 1fr 1fr;
      gap: 2.5rem;
    }
  }

  &__information-title {
    color: $color-white;

    /* 01 Body/semibold */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 150% */

    &:focus-visible {
      background-color: $color-primary-light;
      outline: none;
    }
  }

  &__information-value {
    margin: 0; // reset browser style
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */

    @include media-breakpoint-down('md') {
      &.corepass-id {
        inline-size: 350px;
        overflow-wrap: break-word;
      }
    }

    & + & {
      margin-block-start: calc(1.5rem - 0.25rem);
    }
  }

  &__warning {
    margin: 0; // reset browser style
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
}

.timer {
  cursor: help;

  &__text {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__title {
    color: $color-gray-100;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }

  &__icon {
    color: $color-gray-200;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }

  &__tooltip {
    color: $color-gray-100;
    background-color: $color-gray-500;
    min-width: 20rem;
    padding: 0.75rem;

    &:before {
      border-color: var(--tooltip-background-color, $color-gray-500) transparent transparent;
    }
  }
}
