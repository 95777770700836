@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.kyc-watch-tutorial-trigger {
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: underline;
  text-underline-offset: 0.25rem;
  color: $color-primary-light; // TODO: not a solid contrast to the eyes
  border-radius: 3px;
  transition: all 0.24s ease-in-out;
  text-align: center;

  &:hover {
    filter: brightness(110%);
    text-underline-offset: 0.5rem;
  }
}

.kyc-watch-tutorial-modal {
  inline-size: 52rem;
  background: $color-white;
  border-radius: 1.125rem;
  position: relative;

  [class*='modal-header'] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1.5rem 1.5rem 1rem;
    gap: 1.5rem;

    @include media-breakpoint-up('xl') {
      padding: 0;
      position: absolute;
      inset-block-start: calc(1.5rem + 1rem);
      inset-inline-end: calc(1.5rem + 1rem);
      z-index: 20;
    }
  }

  [class*='modal-close-button'] {
    color: $color-corepass-gray-medium;

    @include media-breakpoint-up('xl') {
      color: $color-white;
    }
  }

  [class*='modal-title'] {
    margin-block-start: 2rem;
    color: $color-corepass-black;
    flex-grow: initial;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    letter-spacing: -0.02em;

    @include media-breakpoint-up('xl') {
      padding-block-start: 1.5rem;
    }
  }

  [class*='modal-content'] {
    padding: 0 1.5rem 1.5rem;
    margin-block-start: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
    color: $color-corepass-gray-medium;
    //
    // Hide the scrollbar
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @include media-breakpoint-up('xl') {
      flex-direction: row;
      padding-block-start: 1.5rem;
      margin: 0;
    }
  }

  [class*='tag-group'] {
    margin-block-start: 1rem;
  }

  &__illustration {
    display: flex;
    place-content: flex-start flex-end;

    @include media-breakpoint-up('xl') {
      order: 1;
      z-index: 10;
    }
  }

  &__gif {
    transition: all 0.36s 0.36s ease-in-out;
    object-fit: contain;

    &[data-transition='from'][data-step='1'] {
      transform: scale(1.01);
      opacity: 0;
    }
    &[data-transition='from'][data-step='2'] {
      transform: scale(1.01);
      opacity: 0;
    }
    &[data-transition='enter'] {
      transform: scale(1);
      opacity: 1;
    }
    &[data-transition='leave'] {
      display: none;
    }
  }

  &__instruction {
    display: flex;
    flex-direction: column;
    transition: all 0.36s ease-in-out;

    @include media-breakpoint-up('xl') {
      padding: 1.5rem;
      max-inline-size: calc(18.5rem + 2 * 1.5rem);
    }
    @include media-breakpoint-up('xxl') {
      padding: 1.5rem 1.5rem calc(1.5rem + 4rem);
    }

    &[data-transition='from'][data-step='1'] {
      transform: translateX(-100%);
    }
    &[data-transition='from'][data-step='2'] {
      transform: translateX(100%);
    }
    &[data-transition='enter'] {
      transform: translateX(0);
    }
    &[data-transition='leave'] {
      display: none;
    }
  }

  &__step-indicators {
    margin-block-start: 0.5rem;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;

    @include media-breakpoint-up('xl') {
      margin-block-start: 0;
    }
  }

  &__step-indicator {
    block-size: 0.25rem;
    inline-size: 0.25rem;
    aspect-ratio: 1;
    background: $color-corepass-secondary;
    border-radius: 2px;

    &[aria-current='true'] {
      inline-size: 1.5rem;
      aspect-ratio: initial;
      background: $color-corepass-primary;
    }
  }

  &__description {
    margin-block: 0.5rem 0;
  }

  &__highlight {
    color: $color-white;
    font-weight: 600;
  }
}

.document {
  flex-direction: column;

  [class*='tag'] {
    border-color: $color-corepass-secondary;
  }

  &__icon {
    color: $color-corepass-primary;
  }

  &__title {
    /* 02 Body/medium */
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: -0.01em;
  }
}

.step-button {
  margin-block: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  user-select: none;

  @include media-breakpoint-up('xl') {
    position: absolute;
    inset-block-end: 0;
  }
  @include media-breakpoint-up('xxl') {
    inset-block-end: 0.75rem;
  }

  &[aria-details='back'] {
    align-self: flex-start;
    flex-direction: row-reverse;

    &:hover {
      --transform-icon: translateX(-0.5rem);
    }
  }

  &[aria-details='next'] {
    align-self: flex-end;

    &:hover {
      --transform-icon: translateX(0.5rem);
    }
  }

  &__text {
    /* 02 Body/semibold */
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    text-align: center;
    color: $color-gray-700;
  }

  &__icon {
    color: $color-primary-light;
    transition: transform 0.24s ease-in-out;
    transform: var(--transform-icon);
  }
}
