@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

@keyframes pulse {
  to {
    opacity: 0;
  }
}

.verification-qr-code {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 2.5rem;

  @include media-breakpoint-up('lg') {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  &__context {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    margin: 0; // reset browser style
    color: $color-white;

    /* 01 Body/semibold */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    /* 150% */

    @include media-breakpoint-up('lg') {
      /* Headlines/H4 */
      font-family: inherit;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.875rem;
      /* 150% */
      letter-spacing: -0.0125rem;
    }
  }

  &__description {
    margin: 0; // reset browser style
    margin-block-start: 0.25rem;
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */

    @include media-breakpoint-up('lg') {
      margin-block-start: 0.5rem;
    }
  }

  &__specs-list {
    margin-block-start: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: 0.75rem 1.5rem;

    @include media-breakpoint-up('xxl') {
      grid-template-columns: 1fr 1fr;
      margin-block-start: 2rem;
    }
  }

  &__specs-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__specs-title {
    color: $color-white;
    opacity: 0.9;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
  }

  &__specs-icon {
    color: $color-gray-100;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }

  &__presentation {
    display: none;
    padding: 3rem 1.5rem 4rem 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    border-radius: 1rem;
    border: 1px solid $color-gray-400;
    background: $color-gray-600;

    @include media-breakpoint-up('lg') {
      display: flex;
      inline-size: 30.125rem;
    }
  }

  &__timer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__contrast {
    inline-size: 21.5rem;
    block-size: 21.5rem;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    position: relative;
    overflow: hidden;

    border-radius: 1rem;
    background: $color-white;

    &[aria-busy='true']::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      z-index: 10;
      translate: -50% -50%;
      inline-size: 19rem;
      block-size: 19rem;
      aspect-ratio: 1;
      background-color: rgba($color-gray-100, 0.72);
      animation: pulse 1.2s steps(120) alternate-reverse infinite;
    }
  }

  &__qr-code {
    inline-size: 19rem;
    block-size: 19rem;
    aspect-ratio: 1;

    &[aria-hidden='true'] {
      display: none;
    }
  }

  &__send-request {
    inline-size: 100% !important;
    display: flex;
    padding: 0.875rem 2rem;
    justify-content: center;
    align-items: center;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
    letter-spacing: 0.0175rem;
    text-transform: uppercase;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  &__loading-overlay {
    position: absolute;
    inset: 0;
    background: $color-white;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.timer {
  cursor: help;

  &__text {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__title {
    color: $color-gray-100;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
  }

  &__time {
    color: $color-white;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.0175rem;
    text-transform: uppercase;
  }

  &__icon {
    color: $color-gray-200;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }
}

.timer-tooltip {
  color: $color-gray-100;
  background-color: $color-gray-500;
  min-width: 20rem;
  padding: 0.75rem;

  &:before {
    border-color: var(--tooltip-background-color, $color-gray-500) transparent transparent;
  }
}
