@import '@ping/assets/scss/theme/variables';
@import '@ping/uikit/Table/style.module.scss';

.empty-view__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  position: absolute;
  top: calc(50% + $header-height);
  left: 50%;
  transform: translate(-50%, -50%);

  .empty-view__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-500;
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 8px;

    svg {
      height: 24px;
      width: 24px;
      stroke: $color-gray-100;
    }
  }

  .empty-view__title {
    /*Body/01 Semibold*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $color-white;
    text-align: center;
  }

  .empty-view__caption {
    color: $color-gray-200;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
