@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.kyc-card-content {
  &__title {
    font-weight: 700;
    font-size: 20px;
    color: $color-white;
    line-height: 30px;
  }

  &__text {
    max-width: 100%;
    margin: auto;

    @include media-breakpoint-up('lg') {
      max-width: 80%;
    }
    @include media-breakpoint-up('xl') {
      max-width: 50%;
    }
    @include media-breakpoint-up('xxl') {
      max-width: 482px;

      margin: 0;
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    margin-block-start: 40px;
    margin-inline-end: 6px;
    gap: 5px;
    color: $color-gray-100;
  }
  &__tooltip {
    cursor: pointer;

    &:hover {
      svg {
        color: $color-white;
      }
    }
    svg {
      transition: $transition-hover;
    }
  }
  &__tooltip-text {
    background-color: $color-gray-500;
    min-width: 20rem;
    padding: 12px;

    &:before {
      border-color: var(--tooltip-background-color, $color-gray-500) transparent transparent;
    }
  }

  &__text-paragraph {
    display: block;
    margin-block-start: 12px;
    font-size: 16px;
    color: $color-gray-100;
    margin-block-end: 0;
  }
  &__text-qr {
    display: inline-block;
    color: $color-gray-100;
    font-size: 14px;
  }
  &__spinner {
    display: inline-block;
  }
  &__text-paragraph-white-text {
    color: $color-white;
  }
  &__step-two-title {
    margin-block-start: 24px;
  }

  &__divider {
    margin-block-start: 2rem;
  }

  &__step-two-text {
    font-size: 14px;
    margin-block-start: 32px;
    line-height: 20px;
  }
  &__text-icon-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-evenly;
    margin-block-start: 16px;
    gap: 6px;

    @include media-breakpoint-up('sm') {
      flex-direction: row;
    }
  }
  &__text-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-gray-400;
    border-radius: 16px;
    padding: 5px 12px;
    color: $color-white;

    svg {
      margin-inline-end: 7px;
    }
  }

  &__qr {
    background: white;
    width: 344px;
    height: 344px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: 12px;
    margin-inline: auto;

    @include media-breakpoint-up('xxl') {
      margin-inline: 0;
    }
  }

  &__image-qr {
    width: 432px;
    height: 568px;
  }

  &__image {
    display: none !important;
    border-radius: 16px;
    border: 1px solid $color-gray-400;
    background: $color-gray-600;
    padding: 42px 22px 4px 24px;
    max-width: 482px;
    width: 100%;
    text-align: center;

    @include media-breakpoint-up('xxl') {
      display: block !important;
    }
  }

  &__request-btn {
    text-transform: uppercase;
    width: 100% !important;
    margin: 20px auto 0;

    @include media-breakpoint-up('sm') {
      max-width: 192px;
      margin: 40px auto 0;
    }
    @include media-breakpoint-up('xxl') {
      margin: 0;
      margin-block-start: 40px;
    }
  }
}
