@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

// TODO: make the skeleton available for other places
.skeleton {
  pointer-events: none;
  position: relative;
  color: $color-gray-700 !important;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    background: linear-gradient(
        126deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(146, 138, 138, 0.2) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      $color-gray-500;

    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s shine ease-in-out infinite;
  }
}

.receive-transaction {
  $deposit: &;

  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  position: relative;
  gap: 1.5rem;

  &[data-is-asset-selected='false'] > section {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-is-network-selected='false'] > section:not(#networks-dropdown) {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-deposit-address-loading='true'] > section {
    visibility: visible;
  }

  &__divider {
    margin-inline-start: -2rem;
    inline-size: calc(100% + 2 * 2rem);
  }

  &__asset {
    display: flex;
    flex-direction: column;
    gap: 0.725rem;
  }

  &__coin-select-label {
    display: block;
    color: $color-gray-100 !important;
    margin-block-end: 0.5rem;
  }

  &__asset-balance {
    color: $color-gray-200 !important;
    text-align: right;
    margin-inline-start: auto;

    #{$deposit}[data-is-asset-selected='false'] & {
      visibility: hidden;
    }
    #{$deposit}[data-deposit-address-loading='true'] & {
      visibility: visible;
      @extend .skeleton;
    }
  }

  &__address-section {
    $space: 1.5rem;

    border-block: 1px solid $color-gray-400;
    padding-block: $space;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $space;
  }

  &__qr-code-wrapper {
    display: none;

    @include media-breakpoint-up('lg') {
      $size: 168px;

      min-width: $size;
      min-height: $size;
      background: $color-white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__qr-code {
    #{$deposit}[data-deposit-address-loading='true'] & {
      display: none;
    }
  }

  &__address-details-wrapper {
    width: 100%;
  }

  &__address-details {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 4px;
  }

  &__address-title {
    color: $color-gray-100;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__address-copy {
    margin-inline-start: auto;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__address-text {
    grid-column: 1 / -1;
    overflow-wrap: break-word;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__button-block {
    margin-block-start: 20px;

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      gap: 10px;
      width: 202px;
      height: 40px;
      border: 1px solid $color-gray-300;
      border-radius: 3px;
      color: $color-white;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
        border-color: $color-gray-500;
        color: $color-white;
      }
    }

    @include media-breakpoint-down('lg') {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      &-button {
        width: 100%;
      }
    }

    span {
      margin-block-start: 12px;
      display: block;
    }
  }

  &__info-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  &__info-text {
    &:nth-of-type(odd) {
      color: $color-gray-100;
    }
    &:nth-of-type(even) {
      text-align: right;
    }

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__footer-text {
    color: $color-gray-100 !important;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }
}
