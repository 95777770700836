@import '@ping/assets/scss/theme/variables';

.input-number {
  &::placeholder {
    color: $color-gray-100;
    opacity: 0.25;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
