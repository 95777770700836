@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.transfer-on-processing-modal {
  border: 1px solid $color-gray-400;
  padding-block: calc(2 * 1rem + 1.5rem) 0;
  min-block-size: auto;
  max-inline-size: 28rem;
  border-radius: 0;

  @include media-breakpoint-up('lg') {
    gap: 3rem;
    border-radius: 1rem;
    padding-block: 2rem;
  }

  &__close {
    position: absolute;
    inset-block: 0;
    inset-inline-end: 0;
    margin: 1rem;
    width: 1rem;
    height: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem 1.25rem;

    @include media-breakpoint-up('lg') {
      padding-block: 0;
      padding-inline: 2rem;
    }
  }

  &__icon {
    inline-size: 3.5rem;
    block-size: 3.5rem;
    aspect-ratio: 1;
    color: $color-primary-light;
  }

  &__title {
    flex-grow: unset;
    margin-block-start: 1rem;
  }

  &__description {
    color: $color-gray-100;
    text-align: center;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  &__actions {
    justify-content: center;
    padding-block-start: 1.25rem;

    @include media-breakpoint-up('lg') {
      padding-block: 0;
    }
  }

  &__continue {
    inline-size: 17rem;
    padding: 0.875rem 1.5rem;

    border-radius: 0.1875rem;
    border: 1px solid $color-primary;
    color: $color-white;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: 0.0175rem;
    text-transform: uppercase;
  }
}
