@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.kyc-check-status-trigger {
  /* 02 Body HC/semibold */
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  padding: 0.875rem 2rem;
  block-size: 48px;
  inline-size: 100%;
  background: $color-primary;
  border-color: $color-primary;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.24s ease-in-out;

  @include media-breakpoint-up('md') {
    inline-size: 192px;
  }

  //
  // TODO: move it to ButtonA11Y later
  &:hover {
    background-color: $color-primary-medium;
    border-color: $color-primary-medium;
  }
}

.kyc-check-status-modal {
  padding-block: 2rem;
  gap: 1rem;
  max-inline-size: 900px;

  [class*='modal-content'] {
    ul {
      margin-block-start: 24px;
    }
  }

  [class*='modal-actions'] {
    padding-block-start: 1rem;
    justify-content: space-between;
    flex-direction: column;
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-100;
  }

  &__error-message {
    color: $color-white;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  &__list-visibility {
    visibility: hidden;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    letter-spacing: -0.02em;
    color: $color-white;
    opacity: 0.9;
  }

  &__icon {
    aspect-ratio: 1;

    &[data-type='success'] {
      path {
        stroke: $color-success;
      }
    }
    &[data-type='error'] {
      path {
        stroke: $color-error;
      }
    }
  }
  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@include media-breakpoint-up('md') {
  .kyc-check-status-modal {
    [class*='modal-content'] {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px 16px;
      }
    }
    [class*='modal-actions'] {
      flex-direction: row;
    }
  }
}
