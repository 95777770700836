@import '@ping/assets/scss/theme/variables';

.network-select {
  &__title {
    color: $color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  &__subtitle {
    color: $color-white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 8px;
  }
  &__not-found {
    padding-block-start: 2rem;
    text-align: center;
    color: $color-gray-100;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__name {
    color: $color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  &__description {
    color: $color-gray-100;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
