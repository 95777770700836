// @import '@ping/assets/scss/theme/theme';
// @import '@ping/assets/scss/theme/variables';

.tooltip-trigger {
  inline-size: inherit;
  display: flex;
  align-items: center;

  // TODO: why here? find and move it where ever is's needed
  // path {
  //   transition: $transition-hover;
  // }

  // &:hover {
  //   cursor: pointer;
  //   path {
  //     stroke: $color-white;
  //     &:last-child {
  //       fill: $color-white;
  //       stroke: transparent;
  //     }
  //   }
  // }
}
