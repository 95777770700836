@import '@ping/assets/scss/theme/variables';

.user-activity-status-modal {
  min-block-size: auto;
  max-width: 28rem;

  [class*='modal-content'] {
    padding: 32px;
    padding-block-end: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &[data-status='success'] {
      svg {
        color: $color-success;
      }
    }
    &[data-status='danger'] {
      svg {
        color: $color-error;
      }
    }
    &[data-status='warning'] {
      svg {
        color: $color-primary-light;
      }
    }
    svg {
      width: 56px;
      height: 56px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
    margin-top: 16px;
    text-align: center;
  }
  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $color-gray-100;
    margin-block-end: 0;
  }
  &__actions {
    justify-content: center;
    padding-block-end: 32px;
    margin-block-start: 48px;
  }
  &__actions-button {
    background-color: $color-primary;
    width: 100%;
    padding: 14px 24px;
    text-transform: uppercase;
    display: flex;
    width: 272px;
    align-self: center;
    justify-content: center;
  }
}
