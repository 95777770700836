@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

@keyframes pulse {
  to {
    opacity: 0;
  }
}

.sign-form-a-modal-trigger {
  justify-self: flex-end;
  width: 100% !important;
  block-size: 3rem;
  padding: 0.875rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.1875rem;
  background: $color-primary;

  /* 02 Body HC/semibold */
  font-family: inherit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.0175rem;
  text-transform: uppercase;

  @include media-breakpoint-up('md') {
    max-inline-size: 15rem;
  }
}

.sign-form-a-modal-portal {
  max-inline-size: 25rem;

  @include media-breakpoint-up('md') {
    padding: 2rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    min-block-size: 496px;
  }

  &__header {
    align-self: stretch;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @include media-breakpoint-up('md') {
      padding: 0;
    }
  }

  &__title {
    display: none;

    @include media-breakpoint-up('md') {
      display: block;
      text-align: center;

      /* Headlines/H3 */
      font-family: inherit;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem; /* 133.333% */
      letter-spacing: -0.015rem;
    }
  }

  &__subtitle {
    display: none;

    @include media-breakpoint-up('md') {
      display: block;
      color: $color-white;
      text-align: center;
      text-wrap: balance;

      /* 02 Body/regular */
      font-family: inherit;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  }

  &__emphasis {
    color: $color-white;

    /* 02 Body/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }

  &__close {
    margin-inline-start: auto;

    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__content {
    padding: 0rem 1rem 1.25rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &[data-device='non-mobile'] {
      display: none;

      @include media-breakpoint-up('md') {
        display: flex;
        gap: 1rem;
      }
    }

    &[data-device='mobile'] {
      gap: 0.5rem;

      @include media-breakpoint-up('md') {
        display: none;
      }
    }
  }

  &__presentation {
    display: none;

    @include media-breakpoint-up('md') {
      inline-size: 21rem;
      block-size: 21rem;
      aspect-ratio: 1;

      display: flex;
      padding: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      border-radius: 1rem;
      border: 1px solid $color-gray-400;
      background: $color-gray-600;
    }
  }

  &__contrast {
    inline-size: 18rem;
    block-size: 18rem;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    position: relative;

    border-radius: 1rem;
    background: $color-white;

    &[aria-busy='true']::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      z-index: 10;
      translate: -50% -50%;
      inline-size: 16rem;
      block-size: 16rem;
      aspect-ratio: 1;
      background-color: rgba($color-gray-100, 0.72);
      animation: pulse 1.2s steps(120) alternate-reverse infinite;
    }
  }

  &__qr-code {
    inline-size: 16rem;
    block-size: 16rem;
    aspect-ratio: 1;

    &[aria-hidden='true'] {
      display: none;
    }
  }

  &__mobile-title {
    text-wrap: balance;

    /* Headlines/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 150% */
    letter-spacing: -0.0125rem;

    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__mobile-description {
    margin: 0; // reset browser style
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */

    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__corepass-link {
    color: $color-primary-light;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;

    &:is(:active, :hover) {
      color: $color-primary-light;
    }
  }

  &__actions {
    margin-block-start: 1.25rem;
    padding-inline: 1.25rem;
    padding-block-end: calc(1.25rem + env(safe-area-inset-bottom));

    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__open-corepass {
    text-transform: uppercase;
    padding: 0.875rem 1.5rem !important;
    width: 100% !important;

    /* 02 Body HC/semibold */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: 0.0175rem;

    @include media-breakpoint-up('md') {
      width: 15.25rem !important;
    }
  }
}
