@import '@ping/assets/scss/theme/variables';

.balance-summary-modal {
  &__trigger {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &-info {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        transform: rotate(-90deg);
        path {
          fill: $color-gray-100;
        }
      }
    }
  }

  &__divider {
    margin: 24px 0;
    background-color: $color-gray-500;
  }

  &__asset-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_amount {
      margin-block-start: 16px;
    }
  }

  &__portal {
    display: flex;
    width: 576px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid $color-gray-400;
    background: $color-gray-700;
    padding: 32px 0 64px;
    max-block-size: 65%;

    &__close {
      position: absolute;
      inset-block-start: 1rem;
      inset-inline-end: 1rem;
      z-index: 999;
      padding: 0;
      border-radius: 5px;

      svg {
        color: $color-white;
        filter: drop-shadow(0 0 0.5rem $color-black);

        &:hover {
          color: $color-gray-100;
        }
      }
    }

    &-content {
      width: 100%;
    }

    &-header {
      margin-block-end: 32px;
      padding: 0;
      span {
        font-weight: 700;
      }
    }
  }
}
