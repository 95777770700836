@import '@ping/assets/scss/theme/variables';

.limits-warning {
  display: flex;
  border-radius: 4px;
  border: 1px solid $color-gray-500;
  background-color: $color-gray-600;
  padding: 16px;
  column-gap: 12px;

  &__icon {
    color: $color-warning;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }

  &__heading {
    color: $color-warning;
    display: block;
    text-wrap: balance;
  }

  &__link {
    text-align: right;
    color: $color-primary-light;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $color-primary-light;
    }
  }
}
