@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.form-b-card {
  &__content {
    display: grid;

    @include media-breakpoint-up('lg') {
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  &__subtitle {
    margin: 0; // rest default style
    color: $color-white;

    /* Headlines/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 150% */
    letter-spacing: -0.0125rem;
  }

  &__description {
    margin: 0; // rest default style
    color: $color-gray-100;

    /* 01 Body/regular */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  &__divider {
    margin-block-start: 1.5rem;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  &__wizard {
    margin-block-start: 2rem;

    @include media-breakpoint-up('lg') {
      margin-block-start: 0;
    }
  }
}
