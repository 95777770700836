@import '@ping/assets/scss/theme/variables';

.form-b-wizard-next {
  max-inline-size: calc(50% - 1.5rem * 0.5);
  flex: 1;
  display: flex;
  padding: 0.875rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.1875rem;
  background: $color-primary;
}
