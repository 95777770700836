@import '@ping/assets/scss/theme/variables';

.verification-expired {
  background-color: $color-gray-600;
  border-radius: 8px;
  border: 1px solid $color-gray-400;
  padding: 16px;
  margin-block-end: 24px;

  &__title {
    color: $color-error;
    display: flex;
    align-items: center;
  }
  &__title-text {
    color: $color-error;
  }
  &__title-icon {
    width: 24px;
    height: 24px;
    color: $color-error;
    margin-inline-end: 7px;
  }

  &__description {
    margin-block-start: 4px;
    color: $color-gray-100;
    margin-inline-start: 31px;
    display: block;
  }
}
