@import '@ping/assets/scss/theme/variables.scss';

.search-field {
  &__control {
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    background: $color-gray-300;
    color: $color-gray-100;
  }

  &__input {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    outline: none;
    flex-grow: 1;
    color: inherit;

    &::placeholder {
      color: inherit;
    }
  }
}
