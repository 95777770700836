@import '../../../../../assets/scss/theme/variables';

.verification-modal {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  gap: 48px;
  border-radius: 16px;
  border: 1px solid $color-gray-400;
  background: $color-gray-700;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    padding: 0;
  }

  &__icon {
    width: 48px;
    height: 48px;
  }

  &__title {
    color: $color-white;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  &__description {
    color: $color-gray-100;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
