@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.withdraw-tier-upgrade {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 576px;

  &__title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
  }

  &__content {
    margin-block-start: 24px;
  }

  span {
    color: $color-gray-100;
    display: block;
    margin-block-end: 32px;
  }

  &__link {
    color: $color-primary-light;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $color-primary-light;
    }
  }

  &__actions {
    display: flex;
    margin-block-start: 16px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  &__button {
    width: 244px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: flex;
    padding: 14px 24px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 3px;
    border: 1px solid $color-primary;
    background-color: transparent;

    &-link {
      width: 244px;
      display: contents;
      &[aria-disabled='true'] {
        a {
          background: $color-gray-400;
          border-color: $color-gray-400;
          color: $color-gray-200;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      a {
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        display: flex;
        padding: 14px 24px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 3px;
        background-color: $color-primary;
        justify-content: center;
        color: $color-white;
        &:hover {
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }
}
