@import '@ping/assets/scss/theme/variables';

.no-asset-view {
  &__icon {
    color: $color-gray-100;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }
}
