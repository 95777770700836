@import '@ping/assets/scss/theme/variables';

.tooltip-withdrawal-limits-info {
  display: grid;
  text-align: left;
  padding: 12px;
  gap: 12px;
  width: 256px;
  font-size: 12px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;

  &__heading {
    color: $color-white;
  }

  &__info {
    font-weight: 400;
    color: $color-gray-100;
  }

  &__link {
    text-align: right;
    color: $color-primary-light;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $color-primary-light;
    }
  }
}
