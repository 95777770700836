@import '../../../../../assets/scss/theme/variables';

.verification-modal {
  padding: 32px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  align-items: center;
  gap: 48px;
  border-radius: 16px;
  background: $color-gray-700;
  border: 1px solid $color-gray-400;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0;
  }

  &__icon {
    width: 56px;
    height: 56px;
    color: $color-primary-light;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
    text-align: center;
    color: $color-white;
    margin: 0;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $color-gray-100;
    margin: 0;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  &__button {
    width: 100%;
    max-width: 384px;
    padding: 14px 24px;
    text-transform: uppercase;
    background-color: $color-primary;
  }
}
