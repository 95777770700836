@import '@ping/assets/scss/theme/variables';

.input-wrapper {
  display: flex;
  place-items: center;
  padding: 12px 12px 12px 16px;
  background: $color-gray-500;
  border-radius: 3px;
  height: 48px;
  gap: 8px;
  .icon {
    path {
      fill: $color-gray-100;
    }
  }
  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: $color-white;
    &::placeholder {
      color: $color-gray-100;
    }
  }
}
