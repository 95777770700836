@import '@ping/assets/scss/theme/variables';

.coin-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $color-white;
  font-size: 14px;

  &__icon {
    height: 1rem !important;
    width: 1rem !important;
  }

  &__title {
    padding-right: 0.75rem;
  }
}
