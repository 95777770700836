@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.kyc-card {
  max-width: $layout-max-width;
  background-color: $color-gray-700;
  border-radius: 16px;
  margin-block-end: 32px;
  padding: 16px;

  @include media-breakpoint-up('md') {
    padding: 30px;
  }
  @include media-breakpoint-up('xxl') {
    padding: 48px 0;
    margin-inline: auto;
  }

  &__header {
    padding: 0;

    @include media-breakpoint-up('xxl') {
      padding: 0 48px 0 48px;
    }
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-block-end: 32px;
    margin-block-end: 32px;
    border-block-end: 1px solid $color-gray-300;
    width: 100%;

    @include media-breakpoint-up('xxl') {
      flex-direction: row;
      padding-block-end: 40px;
      margin-block-end: 40px;
    }
  }

  &__header-title {
    font-weight: 700;
    color: $color-white;
    align-self: center;
    margin-block-end: 0;
    margin-block-start: 8px;

    @include media-breakpoint-up('xxl') {
      margin-block-start: 0px;
      margin-inline-end: auto;
      margin-inline-start: 16px;
    }
  }

  &__header-icon {
    align-self: center;
    border-radius: 50%;
    border: 1px solid $color-primary;
    width: 48px;
    height: 48px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
    }
  }
}

.kyc-card-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: $color-white;

  @include media-breakpoint-up('xxl') {
    flex-direction: row;
    padding-inline: 48px;
  }
}
